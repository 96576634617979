<template>
  <div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          ref="refCompanyListTable"
          class="position-relative list-view-table"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Ничего не найдено!"
          :sort-desc.sync="isSortDirDesc"
          no-local-sorting
        >
          <template #cell(company)="data">
            {{ get(data, 'item.company.name') }}
          </template>
          <template #cell(role)="data">
            {{ get(data, 'item.role.name') }}<br>
            <small class="text-secondary">
              {{ get(data, 'item.role.desc') }}
            </small>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="acceptInvite(data.item)">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Принять</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { debounce, get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getInvites, deleteInvite, acceptInvite } from '@/services/main-api/business/invites'

export default {
  name: 'MyInvitesListView',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'created_at',
      isSortDirDesc: false,
      from: 0,
      to: 0,
      currentPage: 1,
      totalItems: 0,
      tableColumns: [
        { key: 'company', label: 'Компания', sortable: true },
        { key: 'role', label: 'Роль', sortable: false },
        { key: 'created_at', label: 'Создано', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      items: [],
    }
  },
  watch: {
    perPage() {
      this.getData()
    },
    isSortDirDesc() {
      this.getData()
    },
    sortBy() {
      this.getData()
    },
    currentPage() {
      this.getData()
    },
    searchQuery() {
      this.debouncedSearch()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    get,
    async getData() {
      this.loading = true
      await this.getItems()

      this.loading = false
    },
    async getItems() {
      let search = null
      if (this.searchQuery) {
        search = `${this.searchQuery}`
      }

      try {
        const res = await getInvites({
          perPage: this.perPage,
          sortedBy: this.isSortDirDesc ? 'desc' : 'asc',
          orderBy: this.sortBy,
          page: this.currentPage,
          search,
        })

        this.items = res.data.data
        this.currentPage = res.data.meta.current_page
        this.from = res.data.meta.from
        this.to = res.data.meta.to
        this.totalItems = res.data.meta.total
      } catch (e) {
        this.items = []
      }
    },
    decrementInviteCountInStore() {
      let newValue = this.$store.getters['invites/count'] - 1
      if (newValue < 0) {
        newValue = 0
      }
      this.$store.commit('invites/SET_COUNT', newValue)
    },
    async deleteItem(item) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Приглашение от компании ${'Имя компании'} будет удалено`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          await deleteInvite(item.id)
          this.getData()
          this.decrementInviteCountInStore()
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'BellIcon',
              title: 'Удалено!',
              text: 'Приглашение было удалено.',
              variant: 'success',
            },
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async acceptInvite(item) {
      const resButton = await this.$swal({
        title: 'Внимание!',
        text: `Приглашение от компании ${'Имя компании'} будет принято`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Да, принять!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          await acceptInvite(item.id)
          this.getData()
          this.decrementInviteCountInStore()
          this.$store.dispatch('workingMode/getData')
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'BellIcon',
              title: 'Принято!',
              text: 'Приглашение было принято.',
              variant: 'success',
            },
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    // eslint-disable-next-line func-names
    debouncedSearch: debounce(async function () {
      this.currentPage = 1
      await this.getData()
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
